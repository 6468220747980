:root {
  --textcolor: rgb(235, 228, 231);
  --darktextcolor: rgb(92, 97, 102);
  --brightcolor: rgb(219, 216, 255);

  --buttoncolor: rgb(104, 187, 222);
  --buttonbackground: #3c4365;
  --buttonactivebackground: #2c41aa;

  --linkcolor: rgb(206, 213, 221);
  --linkdarkcolor: rgb(216, 223, 231, 0.9);

  --highlitecolor: rgba(0, 96, 250, 0.1);
  --resourcehighlitecolor: rgba(0, 196, 250, 0.2);
  --heavyhighlitecolor: rgba(0, 121, 250, 0.1);
  --highliteprojectcolor: rgba(0, 96, 250, 0.05);

  --sectioncolor: #24283b5b;
  --sectioncolor1: #202b635b;
  --shadowcolor: #1e285a5b;

  --backgroundcolor: #232121;

  --linkgraycolor: rgba(206, 223, 231, 0.5);

  --footercolor: #29283b;
  --headercolor: #29283b;
  --contextmenucolor: #3a5f63;

  --modaloverlaybackground: #27292a8b;
  --modalcontentcolor: #1b1927;
  --modalcontentbrightcolor: #2e2b3d;

  --toastbackgroundcolor: #2d2c38;

  --inputbackgroundcolor: #0b0d14;

  /* #323754 */
  /* tooltips */
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 1;
  --rt-transition-show-delay: 0.35s;
  --rt-transition-closing-delay: 0.35s;

  --font-size-tiny: 10px;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-large: 16px;
  --font-size-xlarge: 20px;
}

body {
  background-color: var(--backgroundcolor);
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.App {
  position: fixed;
  text-align: center;
  color: var(--textcolor);
  /* text-align: left; */
  width: 100%;
  height: 100%;
  /* background-color: #323754; */
  /* Light text for dark background */
  /* background-color: #282c34; */
  /* Dark background */
  /* animate evereything */

  /* all divs should slide in */
  /* animation: slideIn 0.5s; */
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.App-logo {
  width: 100%;

  pointer-events: none;
  position: absolute;
  z-index: -10;
  /* opacity: 0.1; */
  /* tint image*/
  /* filter: invert(100%); */
  filter: sepia(0) saturate(1.05) hue-rotate(70deg) opacity(0.01);
}

.Spaces-logo {
  left: 0;
  top: 0;
  float: left;
  width: 100%;
  pointer-events: none;
  position: absolute;
  transform: translateY(-20%);
  z-index: -10;
  /* opacity: 0.1; */
  /* tint image*/
  /* filter: invert(100%); */
  filter: sepia(0) saturate(1.05) hue-rotate(70deg) opacity(0.01);
}

.App-header {
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  margin: 0%;
}

a {
  color: var(--linkcolor);
}

a :hover {
  color: var(--brightcolor);
}

a :visited {
  color: var(--darktextcolor);
}

optgroup {
  color: var(--linkcolor);
}

.my-optgroup {
  color: var(--linkcolor);
}

h1 h2 h3 h4 h5 h6 {
  /* font-family: "Rubik Mono One"; */
}

h1 {
  /* font-size: calc(var(--font-size-xlarge) + 1vw); */
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.38),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.16),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.14),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.15); /* Shadow effect for readability */
}

h2 {
  /* font-size: calc(var(--font-size-large) + 0.8vw); */
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.28),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.16),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.14),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.15); /* Shadow effect for readability */
}

h3 {
  /* font-size: calc(var(--font-size-large) + 0.6vw); */
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.28),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.16),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.14),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.15); /* Shadow effect for readability */
}

h4 {
  /* font-size: calc(var(--font-size-medium) + 0.4vw); */
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.18),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.6),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.4),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect for readability */
}

h5 {
  /* font-size: calc(var(--font-size-medium) + 0.2vw); */
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.18),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.16),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.14),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.15); /* Shadow effect for readability */
}

h6 {
  /* font-size: calc(var(--font-size-small) + 0.2vw); */
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.18),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.16),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.14),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.15); /* Shadow effect for readability */
}
/* @media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
} */

.enlarge-shrink-text {
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  font-size: 0.7rem; /* Adjust the font size as needed */
  overflow: hidden; /* Optional: Hide overflow if the text is too long */
  text-overflow: ellipsis; /* Optional: Add ellipsis if the text overflows */
  position: absolute;
  right: 15px; /* Adjust the position as needed */
  top: 5px;
}

.terms {
  margin: 2%;
  padding: 2%;
  border-radius: 15px;
  position: relative;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: justify;
  /* background-color: var(--sectioncolor); */
}
/* should be on right side positioned absolute */
.wallet {
  margin: 0px;
  padding: 0px;
  /* border-radius: 5px; */
  position: fixed;
  left: 0px;
  right: 0px;
  height: 3vh;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  z-index: 102;
  background-color: var(--headercolor);
  /* background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--headercolor) 50%
  ); */

  /* background-color: var(--sectioncolor); */
}
.wallet-header {
  margin: 0%;
  padding: 0%;
  border-radius: 15px;
  position: fixed;
  right: 1rem;
  top: 0%;
  z-index: 10;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  /* background-color: var(--sectioncolor); */
  cursor: pointer;
}
.wallet-content {
  background-color: #fdecff;
  color: #6e2f7e;
  padding: 10px 10px 20px 10px;
  border-radius: 15px;
  position: absolute;
  transform: translate(0%, 40%);
}
.wallet-menu {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: fixed;
  right: 1vw;
  left: 2vw;
  background-color: var(--headercolor);

  padding-top: 5vw;
  padding-bottom: 5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 87vw;
}
.user-top-meu {
  z-index: 105;
  top: 0.2rem;
}
.progress-bar-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: var(--highlitecolor);
  z-index: 1000;
}

.downloader {
  /* margin: 15px;
  padding: 15px; */
  border-radius: 15px;
  position: relative;
  flex-direction: column;
  text-align: left;
  align-items: left;
  justify-content: left;
  background-color: var(--sectioncolor);
  padding-left: 3%;
}

.uploader {
  margin: 1%;
  padding: 1%;
  border-radius: 15px;
  position: relative;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  background-color: var(--sectioncolor);
}

select {
  margin: 5px;
  border-radius: 15px;
  border: 2px solid var(--footercolor); /* Visible border for input */
  background-color: #282c34; /* Background color for input */
  padding: 10px 15px; /* More padding for better visibility */
  color: var(--textcolor); /* White text for inputs */
}

input {
  margin: 5px;
  border-radius: 15px;
  border: 2px solid var(--footercolor); /* Visible border for input */
  background-color: var(--inputbackgroundcolor);
  padding: 10px 15px; /* More padding for better visibility */
  color: var(--textcolor); /* White text for inputs */
  box-shadow: 0px 0px 50px 10px var(--shadowcolor);
  transition: box-shadow 0.3s, color 0.3s; /* Transition for hover effect */
}

input:hover {
  background-color: var(--sectioncolor);
  border-color: var(--highlitecolor);
  box-shadow: 0px 0px 25px 10px var(--shadowcolor);
}

input:focus {
  background-color: var(--sectioncolor);
  border-color: var(--linkcolor);
  box-shadow: 0px 0px 15px 5px var(--shadowcolor);
}

input:focus :active ::shadow {
  border-color: var(--linkcolor);
}

textarea {
  margin: 5px;
  border-radius: 15px;
  border: 2px solid var(--footercolor); /* Visible border for input */
  background-color: var(--inputbackgroundcolor);
  padding: 10px 15px; /* More padding for better visibility */
  color: var(--textcolor);
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}

/* Buttons */
button {
  margin: 5px;
  border-radius: 15px;
  border: 2px solid var(--sectioncolor1); /* Visible border for button */
  background-color: var(
    --buttonbackground
  ); /* Button background color on hover */
  color: var(--buttoncolor); /* White text for buttons */
  padding: 10px 15px; /* More padding for better visibility */
  cursor: pointer; /* Indicates a clickable button */
  transition: background-color 0.3s, color 0.3s; /* Transition for hover effect */
  transition: box-shadow 0.3s, color 0.3s; /* Transition for hover effect */
  box-shadow: 0px 0px 100px 1px var(--shadowcolor);
}

button:hover {
  background-color: var(--buttonactivebackground); /* Button background color */
  color: var(--brightcolor); /* Button text color on hover */
  border-radius: 50px;
  transform: scale(1.05);
  box-shadow: 0px 0px 30px 15px var(--shadowcolor);
}

button:disabled {
  color: #7d75ac;
  background-color: #b1abde;
}

ul {
  list-style-type: none; /*  square This removes the bullet points */ /* This removes the bullet points */
  animation: fadeIn 0.2s ease-in-out forwards;
}

ol {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.menu-selected {
  /* background-color: var(--highlitecolor); */
  color: var(--linkcolor);
  border-radius: 15px;
  cursor: pointer;
  /* font-weight: bolder; */
  /* font-size: larger; */
  font-weight: 600;
}
.menu-normal {
  /* background-color: var(--highlitecolor); */
  color: var(--textcolor);
  border-radius: 15px;
  cursor: pointer;
  font-weight: 100;
}

.resource-list {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 15px;
  border-radius: 15px;
  position: relative;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.resource-item {
  margin: 1px;
  padding: 1px;
  border-radius: 0px;
  position: relative;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: left;
  cursor: pointer;
  align-items: last baseline;
  color: var(--linkcolor);
  animation: fadeIn 0.2s ease-in-out forwards;
}
.resource-item:hover {
  background-color: var(--resourcehighlitecolor);
  text-decoration: underline;
  color: var(--brightcolor);
}

.resource-item-project {
  margin: 1px;
  padding: 1px;
  border-radius: 0px;
  position: relative;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: left;
  cursor: pointer;
  align-items: last baseline;
  color: var(--linkcolor);
  /* animation: fadeIn 0.2s ease-in-out forwards; */
}
.resource-item-project:hover {
  background-color: var(--highliteprojectcolor);
  /* text-decoration: underline; */
  color: var(--brightcolor);
}

.resource-not-important {
  /* color: gray; */
  /* text-decoration: overline; */
  /* cursor: not-allowed; */
}

.resource-1-item {
  margin: 3px;
  padding: 1px;
  border-radius: 0px;
  animation: fadeIn 0.2s ease-in-out forwards;
}
.resource-1-item:hover {
  background-color: var(--resourcehighlitecolor);
  text-decoration: underline;
  color: var(--brightcolor);
}

@media (max-width: 1024px) {
  .resource-not-important {
    display: none;
    visibility: hidden;
  }
}

.resource-row {
  margin: 1px;
  padding: 1px;
  border-radius: 0px;
  position: relative;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: left;
  align-items: last baseline;
  color: var(--linkcolor);
  animation: fadeIn 0.2s ease-in-out forwards;
}
.resource-row:hover {
  background-color: var(--heavyhighlitecolor);
  text-decoration: underline;
  color: var(--brightcolor);
}
.resource-highlite-row {
  color: var(--linkcolor);
}
.resource-highlite-row:hover {
  color: var(--brightcolor);
  background-color: var(--heavyhighlitecolor);
}
.resource-highlite-ready-drop {
  color: var(--linkcolor);
  background-color: var(--resourcehighlitecolor);
}

.resource-item-dark {
  margin: 1px;
  padding: 1px;
  border-radius: 0px;
  position: relative;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: left;
  cursor: pointer;
  align-items: last baseline;
  color: var(--linkdarkcolor);
}

.resource-item-dark:hover {
  background-color: var(--highlitecolor);
  text-decoration: underline;
  color: var(--brightcolor);
}

.link {
  padding: 0% 1%;

  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
  color: var(--linkcolor);
  /* font-weight: bolder; */
}

.footer {
  position: fixed; /* This makes the footer stick to the bottom */
  bottom: 0; /* This positions the footer at the bottom of the page */
  width: 100%; /* This makes the footer span the full width of the page */
  z-index: 101; /* This makes the footer stay on top of other elements */
  background-color: var(
    --footercolor
  ); /* This gives the footer a background color */
  min-height: 1rem; /* This gives the footer a minimum height */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  margin: 0;
  padding: 5px;
  text-align: center;
}

.filter-search {
  height: 0.3rem;
  position: fixed;
  bottom: 1.6rem;
  left: 0rem;
  right: 1rem;
  background-color: var(--footercolor);
  z-index: 100;
}

.users {
  margin: 5px;
  padding: 15px;
  border-radius: 15px;
  position: relative;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  /* background-color: #c1b7fa; */
  text-align: left;
}

.users-tree {
  /* margin: 5px;
  padding: 5px;
  border-radius: 15px; */
  /* position: relative;
  flex-direction: column;
  align-items: left;
  justify-content: left; */
  /* background-color: #c1b7fa; */
  text-align: left;
}

.search_users {
  border-radius: 15px;
  align-items: left;
  justify-content: left;
  text-align: left;
  /* z-index: 100; */
}

.user-data {
  width: 95%;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--sectioncolor);
  padding: 0px 30px 0px 10px;
  margin: 0px;
  border-radius: 35px;
  box-shadow: 0 0 70px 70px var(--shadowcolor);
}

.user-user-accesible {
  color: var(--linkcolor);
  cursor: pointer;
}
.user-wrong-node {
  color: gray;
  cursor: not-allowed;
}
/* TABS TABS TABS */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
  /* background-color: var(--sectioncolor); */
}

.react-tabs__tab-list {
  border-bottom: 1px solid var(--highlitecolor);
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  overflow-x: auto;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border-color: #aaaaaa70;
  border-radius: 15px 15px 0px 0px;
  animation: fadeIn 0.5s ease-in-out forwards;
  max-height: 5rem;
  font-size: small;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-tabs__tab--selected {
  background: #ffffff1f;
  border-color: var(--highlitecolor);
  color: var(--linkcolor);
  border-radius: 0px 25px 0 0;
  box-shadow: #5a5481;
}

.react-tabs__tab--disabled {
  color: gray;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: var(--highlitecolor);
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.container-tree {
  display: flex;
  top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px; /* Adjust this value as needed */
  height: 100vh;
  text-align: left;
}
.left-side-tree,
.right-side-tree {
  max-height: 91vh; /* This ensures they don't grow beyond the container-tree height */
  overflow-y: auto; /* Keeps existing behavior, allowing for independent scrolling */
}

.left-side-tree {
  width: 80%;
  word-wrap: break-word;
}

.right-side-tree {
  width: 20%;
  padding-top: 3em;
  padding-left: 15px;
  padding-bottom: 40px;
  word-wrap: break-word;
  border-left: 1px solid var(--highlitecolor);
}
@media (max-width: 1024px) {
  .container-tree {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 99vh;
  }
  .left-side-tree {
    width: 100vw;
    border: none;
  }
  .right-side-tree {
    width: 0%;
    visibility: hidden;
    border: none;
  }
}

.waiting-for-space-action {
  color: var(--linkcolor);
  float: none;
  cursor: progress;
  position: fixed;
  /* font-size: xx-small; */
  /* font-style: italic; */
  left: 0.5rem;
  bottom: 4rem;
  background: var(--backgroundcolor);
  /* left text align */
  text-align: left;
}

.commandlist-menu {
  top: 2vh;
  right: 1vw;
  position: fixed;
  display: flex;
  background: var(--footercolor);
  /* left: 50%;
  top: 5vh;


  /* style={{
    top: "30px",
    left: "50%",
    position: "fixed",
    display: "flex",
  }} */
}
.commandlist-menu-button {
  height: 0.5em;
  font-size: 0.8em;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: flex;
}

.glitch {
  /* font-size: 5rem;
  font-weight: bold; */
  text-transform: uppercase;
  position: relative;
  animation: fadeIn 1s ease-in-out forwards;
  text-shadow: 0.05em 0 0 #00fffb75, -0.03em -0.04em 0 #fb00ff70,
    0.025em 0.04em 0 #fffb006c;
  /*animation: glitch 16725ms infinite; */
}
.glitch2 {
  /* font-size: 5rem;
  font-weight: bold; */
  text-transform: uppercase;
  position: relative;
  /* animation: fadeIn 1s ease-in-out forwards; */
  text-shadow: 0.05em 0 0 #00fffb75, -0.03em -0.04em 0 #fb00ff70,
    0.025em 0.04em 0 #fffb006c;
  animation: glitch 16725ms infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 1500ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  transform: translate(-0.02em, -0.015em);
  opacity: 0.75;
}

.glitch span:last-child {
  animation: glitch 2375ms infinite;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  transform: translate(0.02em, 0.015em);
  opacity: 0.75;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #6200ff, -0.03em -0.04em 0 #b700ff77,
      0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #6200ff67, -0.03em -0.04em 0 #b700ff,
      0.025em 0.04em 0 #fffc00;
  }
  /* 16% {
    text-shadow: -0.05em -0.025em 0 #6200ff, 0.025em 0.035em 0 #b700ff77,
      -0.05em -0.05em 0 #fffc00;
  } */
  /* 49% {
    text-shadow: -0.05em -0.025em 0 #6200ff67, 0.025em 0.035em 0 #b700ff,
      -0.05em -0.05em 0 #fffc00;
  } */
  50% {
    text-shadow: 0.05em 0.035em 0 #6200ff, 0.03em 0 0 #b700ff77,
      0 -0.04em 0 #fffc00;
  }
  /* 99% {
    text-shadow: 0.05em 0.035em 0 #6200ff67, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  } */
  100% {
    text-shadow: -0.05em 0 0 #6200ff, -0.025em -0.04em 0 #b700ff77,
      -0.04em -0.025em 0 #fffc00;
  }
}
.space-actions {
  overflow: auto;
}

.space-actions-list {
  margin: 2px;
  padding: 2px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  line-height: 1.3;
  flex: 1;
}
.space-action-group {
  word-wrap: normal;
  white-space: nowrap;
  cursor: default;
  border: 1px dotted;
  border-radius: 15px;
  margin: 1px;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--textcolor);
  word-wrap: normal;
  font-size: calc(var(--font-size-tiny) + 0.2vw);
}
.space-action-item :hover {
  background-color: var(--highlitecolor);
  background: var(--linkdarkcolor);
  text-decoration: underline;
  color: var(--brightcolor);
}
.space-action-item {
  cursor: pointer;
  border: 1px dotted;
  border-radius: 15px;
  margin: 1px;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--linkcolor);
  word-wrap: normal;
  font-size: calc(var(--font-size-tiny) + 0.3vw);
  cursor: grab;
}

.space-action-item :hover {
  background-color: var(--highlitecolor);
  background: var(--linkdarkcolor);
  text-decoration: underline;
  color: var(--brightcolor);
}
/*
.glitch span {
  animation: glitch 1s linear infinite;
}

@keyframes glitchx {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.glitch span:before,
.glitch span:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitch span:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.glitch span:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
} */

/* cy {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */
.faded {
  opacity: 0.5;
}

.spaces-delete {
  color: var(--linkgraycolor);
  cursor: pointer;
}

.spaces-delete:hover {
  color: red;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: block;
  width: 50em;
  height: 10em;
}

.dropdown-group {
  display: block;
  position: absolute;
  min-width: 160px;
  box-sizing: border-box;
  z-index: 100;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.dropdown:hover .dropdown-group {
  display: block;
}

.dropdown-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

.Toastify__toast-container {
  background-color: var(--modaloverlaybackground);
  position: fixed;

  /* border-radius: 15px; */
}
.Toastify__toast-theme--light {
  background-color: var(--toastbackgroundcolor);
  border-radius: 15px;
  box-shadow: 0 0 50px 10px var(--shadowcolor);
  color: var(--textcolor);
}
.Toastify__progress-bar {
  background-color: var(--toastbackgroundcolor);
  background: var(--linkcolor);

  border-radius: 15px;
}

.markmap {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  --markmap-text-color: #fdecff !important;
  /* color: var(--darktextcolor) !important; */
  /*border: var(--shadowcolor) 1px solid;
  box-shadow: 0 0 50px 10px var(--shadowcolor); */
}
#myId .markmap {
  /* width: 100vw;
  height: 80vh; */
  --markmap-text-color: #fdecff !important;
  color: #fdecff !important;
}

.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: visible;
  /* background-color: #ffffff33; */
}

.card-header {
  padding: 16px;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f533;
}

.card-content {
  padding: 16px;
}

.card-footer {
  padding: 16px;
  border-top: 1px solid #ddd;
  /* background-color: #f5f5f5; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.badge-secondary {
  background-color: #6c757d;
  color: #fff;
}

.badge-outline {
  border: 1px solid #6c757d;
  color: #6c757d;
  background-color: transparent;
}

.product-card {
  width: 200px;
  margin: 12px;
}

.toast-card {
  width: 95%;
  margin: 5px;
}

.product-card__image {
  width: 100%;
  height: auto;
}

.product-card__content {
  padding: 16px;
}

.product-card__publisher {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.product-card__publisher-icon {
  width: 24px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 8px;
}

.product-card__publisher-text {
  font-size: 14px;
  color: #e3e3e3;
}

.product-card__title {
  font-size: 18px;
  margin: 8px 0;
}

.product-card__badge {
  margin-bottom: 8px;
}

.product-card__description {
  font-size: 14px;
  color: #bcbcbc;
}

.product-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #ddd;
  /* background-color: #f5f5f533; */
}

.product-card__badge--free {
  border-color: #28a745;
  color: #28a745;
}

.product-card__badge--paid {
  border-color: #dc3545;
  color: #dc3545;
}

.product-card__visitors {
  font-size: 14px;
  color: #666;
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-card__image-container {
  position: relative;
  display: inline-block;
}

.product-card__image {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0.38;
}

.product-card__publisher-text-glow1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust color as needed */
  font-size: 2rem; /* Adjust font size as needed */
  z-index: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: add text shadow for better readability */
}

.product-card__publisher-text-glow {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust color as needed */
  font-size: 1.1rem; /* Adjust font size as needed */
  z-index: 1;
  text-shadow: 0 0 10px rgba(0, 179, 255, 0.8),
    /* Glow effect */ 0 0 20px rgba(255, 255, 255, 0.6),
    /* Glow effect */ 0 0 30px rgba(255, 255, 255, 0.4),
    /* Glow effect */ 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect for readability */
}

.reactgrid-content .rg-pane .rg-cell {
  color: var(--textcolor) !important;
}

/* BottomScrollingPanel.css */

.bottom-scrolling-panel {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  overflow: visible;
  display: flex;
  align-items: baseline;
  padding: 0px 0;
  z-index: 1000; /* Ensure it's above other elements */
  cursor: grab;
}

.bottom-scrolling-panel:active {
  cursor: grabbing;
}

.scrolling-content {
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
}

.scrolling-content.active {
  cursor: grabbing;
  background-color: var(--linkcolor);
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Optional: Style for CharacterPanel components */
.character-panel {
  flex: 0 0 auto;
  width: 150px; /* Adjust width as needed */
  /* height: 3rem;  */
  margin: 0px 10px;
  background-color: var(--modalcontentbrightcolor);
  color: var(--textcolor);
  border-radius: 8px;
  overflow: visible; /* Ensure overflow is visible */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
  transform-origin: center bottom; /* Set the anchor point for scaling */
}

.character-panel.hovered {
  margin: 10px 10px;
  /* width: 200px; */
  transform: scaleX(1.3) translateY(-1em); /* Scale and lift the panel */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  height: fit-content;
  z-index: 1001;
  background-color: var(--resourcehighlitecolor);
}

.character-panel img {
  width: 64;
  height: 64;
  border-radius: 50%;
  object-fit: cover;
}

.character-panel h3 {
  font-size: 12px;
  margin: 10px 0 5px;
}

.character-panel p {
  font-size: 10px;
  margin: 10px 10px 5px;
  /* color: #555; */
}
